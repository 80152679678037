<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-12">
      <div class="grid grid-cols-12 gap-6">
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineComponent, ref, provide } from 'vue'

export default defineComponent({
  setup() {
    const dateRange = ref('')
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    return {
      dateRange,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentDate: 'home/currentDate',
      loading: 'home/loading',
      model: 'home/statistics'
    })
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.dashboard')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.dashboard')
    }
  },
  async mounted() {
    this.doFetch()
  },
  methods: {
    ...mapActions({
      doFetch: 'home/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    goToUser() {
      this.$router.push('/user-view-page')
    },
    goToChef() {
      this.$router.push('/chef-view-page')
    },
    async reloadData() {
      this.doFetch()
      this.$refs.orders.doFetchlatestOrders()
      this.$refs.users.doFetchlatestUsers()
      this.$refs.weeklyTopCities.doFetch()
      this.$refs.monthlyTopCities.doFetch()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
